.react-datepicker__day-names,
.react-datepicker__month {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.react-datepicker__month-wrapper {
  display: flex;
  flex-direction: row;
}
.react-datepicker__year-wrapper {
  display: flex;
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.react-datepicker__year-text {
  width: 84px;
  height: 55px;
  display: flex;
  border-radius: 8px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.react-datepicker-year-header {
  margin: 15px 0 17px 0;
  font-size: 1.6rem;
  font-weight: 500;
  display: block;
  text-align: center;
}
.react-datepicker__navigation {
  color: inherit;
  align-items: center;
  background: none;
  display: flex;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 20px;
  padding: 0;
  border: none;
  z-index: 1;
  height: 20px;
  width: 20px;
  text-indent: -999em;
  overflow: hidden;
}
.react-datepicker__navigation--previous {
  left: 16px;
}
.react-datepicker__navigation--next {
  right: 16px;
}
.react-datepicker__navigation--years {
  position: relative;
  top: 0;
  display: block;
  margin: 0 auto 0 auto;
}
.react-datepicker__navigation--years-previous {
  top: 40px;
}
.react-datepicker__navigation--years-upcoming {
  top: -4px;
}
.react-datepicker__navigation-icon {
  position: relative;
  top: -1px;
  font-size: 20px;
  width: 0;
}
.react-datepicker__navigation-icon::before {
  border-color: inherit;
  border-style: solid;
  border-width: 2px 2px 0 0;
  content: '';
  display: block;
  height: 8px;
  position: absolute;
  top: 4px;
  width: 8px;
}
.react-datepicker__navigation-icon--next {
  left: -2px;
}
.react-datepicker__navigation-icon--next::before {
  transform: rotate(45deg);
  left: -7px;
}
.react-datepicker__navigation-icon--previous {
  right: -2px;
}
.react-datepicker__navigation-icon--previous::before {
  transform: rotate(225deg);
  right: -7px;
}
.react-datepicker__year-text--disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.react-datepicker__navigation--previous--disabled,
.react-datepicker__navigation--next--disabled {
  cursor: default;
  opacity: 0.5;
}
.react-datepicker-popper {
  z-index: 999;
}
